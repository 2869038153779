/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages



        // HEADER
            var winW = $(window).outerWidth();
            var winH = $(window).outerHeight();
            $(document).ready(function(){
              if(winW < 552) {
                $('.hero').css({ height : winH });
                $('.page-header img').css({ height : (winH - 100) });
              }
            });


            $('.main .column ul').each(function(){
              if($(this).find('li').contents().is('strong')) {
                $(this).addClass('ticklist');
              }
            });



        // STICKY HEADER
            var scrollPosition = $(window).scrollTop(); //-- Scroll position
            function scrollCheck() {
              // console.log(scrollPosition);
              scrollPosition = $(window).scrollTop();
              if(scrollPosition > 0) {
                $('header').addClass('sticky');
              } else if(scrollPosition === 0) {
                $('header').removeClass('sticky');
              }
              if(scrollPosition > 100) {
                $('header, .brand').addClass('scrolled');
              } else if(scrollPosition <= 100 ) {
                $('header, .brand').removeClass('scrolled');
              }
            }
            $(window).on('load scroll', function() {
              scrollCheck();
            });
            $(document).ready(function(){
              scrollCheck();
            });




        // Back to top scroll button
            //-- Also use this for the down click on the hero panel.
            //-- Rotate and change function after click or scroll
            // if($('div.page-header').find('img').length || $('body').hasClass('home')) {
            //   $('.backToTop.down').show();
            // }
            // $('.backToTop.up').hide();
            $(window).on('load scroll', function(){
              var scrollPos = window.pageYOffset;
              if(scrollPos < 100){
                if($('div.page-header').find('img').length || $('body').hasClass('home')) {
                  $('.backToTop.down').show();
                }
                $('.backToTop.up').hide();
              } else if(scrollPos >= 100){
                if($('div.page-header').find('img').length || $('body').hasClass('home')) {
                  $('.backToTop.down').hide();
                }
                $('.backToTop.up').show();
              }
            });

            $('.backToTop.down').on('click', function() {
              if($('body').hasClass('home')) {
                $('html, body').animate({
                    scrollTop: $(".hero").next().offset().top - 80
                }, 800);
              } else if($('div.page-header').find('img').length) {
                $('html, body').animate({
                  scrollTop: $(".page-header").next().offset().top - 80
                }, 800);
              }
            });
            // }
            $('.backToTop.up').on('click', function(){
              $('html, body').animate({
                scrollTop: 0,
              }, 800);
            });




        // Search
            $('.search-icon').on('click', function() {
              $(this).parents('form').find('input[type=text]').toggleClass('open');
              $(this).parent('.search-box-label').toggleClass('open');
              $(this).toggleClass('open');
              if($(window).outerWidth() < 552) {
                $('.brand').toggleClass('hide');
              } else if($(window).outerWidth() > 552) {
                $('.nav').toggleClass('hide');
              }
            });




        // Mobile navigation
            $('.nav-icon').on('click', function(){
              // console.log(scrollPosition);
              $('.nav-icon').toggleClass('open');
              // $('#nav-icon').toggleClass('open');
              if($('.nav-icon').hasClass('open')) {
                // Add Classes
                $('body, .wrap, header, footer, .mobile-menu, .brand').addClass('menu-opened');
              } else {
                // Remove Classes
                $('body, .wrap, header, footer, .mobile-menu, .brand').removeClass('menu-opened');
              }
            });

            $('.mobile-menu .nav > li.menu-item-has-children').each(function(){

              var parent = $(this);

              $(parent).prepend('<span class="menu-click"><i class="fas fa-plus"></i></span>');

              $(parent).find('span.menu-click').on('click', function() {

                parent.toggleClass('clicked');
                if(parent.hasClass('clicked')) {

                  $('.mobile-menu .nav > li').removeClass('clicked');
                  $('ul.sub-menu').removeClass('clicked');

                  parent.addClass('clicked');


                  $('span.menu-click').find('i').removeClass('fa-minus');
                  $('span.menu-click').find('i').addClass('fa-plus');

                  $(this).find('i').removeClass('fa-plus');
                  $(this).find('i').addClass('fa-minus');

                  parent.find('ul.sub-menu').addClass('clicked');

                } else {

                  $('.mobile-menu .nav > li').removeClass('clicked');

                  $('ul.sub-menu').removeClass('clicked');

                  $(this).find('i').addClass('fa-plus');
                  $(this).find('i').removeClass('fa-minus');

                  parent.find('ul.sub-menu').removeClass('clicked');

                }
              });
            });




        // STATS
            //-- get waypoints to do stat counter
            $('.stats').waypoint(function() {
              // animation code

              $('.stat h2').each(function(){
                  // console.log($(this).data('value'));
                  $(this).find('.stat-display').prop('Counter',0).animate({
                      Counter: $(this).data('value')
                  }, {
                      duration: 1500,
                      easing: 'swing',
                      step: function (now) {
                          $(this).text(Math.ceil(now).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"));
                      }
                  });
              });
            }, { offset: '85%', triggerOnce: true });



        // Images
            $(document).ready(function(){
              $('.wp-block-image img').each(function(){
                $(this).addClass('foobox');
                // $(this).css({ border : '10px solid #000' });
              });
            });




        // Sliders

            // IMAGE GALLERY SLIDERS
            $('.image-gallery-slider').each(function(key, item) {

              var sliderIdName = 'slider' + key;

              this.id = sliderIdName;
              var sliderId = '#' + sliderIdName;

              $(sliderId).slick({
                accessibility: true,
                slidesToShow: 2,
                slidesToScroll: 2,
                prevArrow: '<div class="slick-button slick-prev"></div>',
                nextArrow: '<div class="slick-button slick-next"></div>',
                dots: true,
                autoplay: false,
                autoplaySpeed: 6000,
                mobileFirst: true,
                responsive: [
                  {
                    breakpoint: 992,
                    settings: {
                      slidesToShow: 4,
                      slidesToScroll: 4,
                    }
                  },
                  {
                    breakpoint: 767,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 3,
                    }
                  }
                ]
              });


            });


            //-- TESTIMONIALS
            $('.testimonials-modal-slider').slick({
              accessibility: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              prevArrow: '<div class="slick-button slick-prev"></div>',
              nextArrow: '<div class="slick-button slick-next"></div>',
              dots: false,
              fade: true,
              autoplay: false,
              asNavFor: '.testimonials-slider, .testimonials-page-slider'
            });
            $('.testimonials-page-slider').slick({
              accessibility: true,
              slidesToShow: 3000,
              slidesToScroll: 3000,
              asNavFor: '.testimonials-modal-slider',
              // prevArrow: '<div class="slick-button slick-prev"></div>',
              // nextArrow: '<div class="slick-button slick-next"></div>',
              dots: false,
              focusOnSelect: true,
            });
            $('.testimonials-slider').slick({
              accessibility: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              asNavFor: '.testimonials-modal-slider',
              prevArrow: '<div class="slick-button slick-prev"></div>',
              nextArrow: '<div class="slick-button slick-next"></div>',
              dots: false,
              focusOnSelect: true,
              mobileFirst: true,
              responsive: [
                {
                  breakpoint: 1200,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                  }
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  }
                }
              ]
            });

            $('.testimonials-modal-slider').slick("setPosition", 0);
            $('.testimonials-modal-slider').slick('refresh');

            // Clicks
            $('.testimonials-slider .testimonial-card').each(function(){
              $(this).on('click', function(e){
                e.preventDefault();

                // console.log('gettin clicked');
                $('.testimonials-slider').slick('slickPause');

                $('.testimonials-modal-slider').slick("setPosition", 0);
                $('.testimonials-modal-slider').slick('refresh');
                $('#testimonialModal').show();
              });
            });
            $('.testimonials-page-slider .testimonial-card').each(function(){
              $(this).on('click', function(e){
                e.preventDefault();
                $('#testimonialModal').show();
                $('.testimonials-modal-slider').slick("setPosition", 0);
                $('.testimonials-modal-slider').slick('refresh');
              });
            });


            //-- MEET THE TEAM
            $('.team-modal-slider').slick({
              accessibility: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              prevArrow: '<div class="slick-button slick-prev"></div>',
              nextArrow: '<div class="slick-button slick-next"></div>',
              dots: false,
              fade: true,
              autoplay: false,
              asNavFor: '.team-slider'
            });
            $('.team-slider').slick({
              accessibility: true,
              slidesToShow: 3000,
              slidesToScroll: 3000,
              asNavFor: '.team-modal-slider',
              // prevArrow: '<div class="slick-button slick-prev"></div>',
              // nextArrow: '<div class="slick-button slick-next"></div>',
              dots: false,
              focusOnSelect: true,
            });

            $('.team-modal-slider').slick("setPosition", 0);
            $('.team-modal-slider').slick('refresh');

            // Clicks
            $('.team-slider .team-card').each(function(){
              $(this).on('click', function(e){
                e.preventDefault();

                // console.log('team gettin clicked');
                $('#teamModal').show();
                // $('#teamModal').addClass('show');
                // $('.team-slider').slick('slickPause');

                $('.team-modal-slider').slick("setPosition", 0);
                $('.team-modal-slider').slick('refresh');
              });
            });


            //-- LOGO BANNER
            $('.logo-banner-content').slick({
              accessibility: true,
              slidesToShow: 2,
              slidesToScroll: 2,
              prevArrow: '<div class="slick-button slick-prev"></div>',
              nextArrow: '<div class="slick-button slick-next"></div>',
              dots: false,
              autoplay: true,
              autoplaySpeed: 6000,
              mobileFirst: true,
              responsive: [
                {
                  breakpoint: 1200,
                  settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                  }
                },
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                  }
                }
              ]
            });


            // PROJECTS CAROUSEL PANELS
            //-- ICON PANEL
            // $('.single.single-projects .icon-panel').slick({
            //   accessibility: true,
            //   slidesToShow: 1,
            //   slidesToScroll: 1,
            //   prevArrow: '<div class="slick-button slick-prev"></div>',
            //   nextArrow: '<div class="slick-button slick-next"></div>',
            //   dots: true,
            //   autoplay: false,
            //   autoplaySpeed: 6000,
            //   mobileFirst: true,
            //   responsive: [
            //     {
            //       breakpoint: 992,
            //       settings: {
            //         slidesToShow: 3,
            //         slidesToScroll: 3,
            //       }
            //     },
            //     {
            //       breakpoint: 767,
            //       settings: {
            //         slidesToShow: 2,
            //         slidesToScroll: 2,
            //       }
            //     }
            //   ]
            // });
            //-- SERVICES SPEC PANEL
            $('.single.single-projects .spec-panel .spec-content').slick({
              accessibility: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              prevArrow: '<div class="slick-button slick-prev"></div>',
              nextArrow: '<div class="slick-button slick-next"></div>',
              dots: true,
              autoplay: false,
              autoplaySpeed: 6000,
              mobileFirst: true,
              responsive: [
                {
                  breakpoint: 992,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                  }
                },
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                  }
                }
              ]
            });





        // CONTENT
            // Find and hide empty p tags
            $('.content-block p').filter( function() {
              var html = $(this).html();
              if(html === '' || html === '&nbsp;') {
                return true;
              }
            }).addClass('emptyP');

            // Center video on single column layout
            $(document).ready(function(){
              $('.one.columns').each(function(){
                if($(this).find('.wp-video').length) {
                  console.log('hiya');
                  $('.one.columns').css({ justifyContent: 'center' });
                }
              });
            });

            //-- Scroll to search filters
            // if($('body.archive').hasClass('projects')) {
            //   if(window.location.search.length) {
            //     console.log('filtered');
            //     $(document).ready(function () {
            //       $('html, body').animate({
            //           scrollTop: $('#filters').offset().top - 130
            //       }, 1);
            //     });
            //   }
            // }



        // ACCORDIONS
            $('.accordion .card-header > div').each(function(){

              var parent = $(this);

              $(parent).prepend('<span class="menu-click"><i class="fas fa-plus"></i></span>');

              $(parent).on('click', function() {

                parent.toggleClass('clicked');
                if(parent.hasClass('clicked')) {

                  $('.accordion .card-header > div').removeClass('clicked');
                  // $('ul.sub-menu').removeClass('clicked');

                  parent.addClass('clicked');

                  $('span.menu-click').find('i').removeClass('fa-minus');
                  $('span.menu-click').find('i').addClass('fa-plus');

                  $(this).find('i').removeClass('fa-plus');
                  $(this).find('i').addClass('fa-minus');

                  parent.find('ul.sub-menu').addClass('clicked');

                } else {

                  $('.accordion .card-header > div').removeClass('clicked');
                  $(this).find('i').addClass('fa-plus');
                  $(this).find('i').removeClass('fa-minus');

                  // parent.find('ul.sub-menu').removeClass('clicked');

                }
              });
            });




        // AJAX Pagination
            var $ajax_list = $('div.posts');
            if ($ajax_list.length) {
              $('body').on('click', '#ajax-pagination', function (e) {
                e.preventDefault();

                var activeClass = 'disabled';

                if ($(this).hasClass(activeClass)) {
                  return;
                }

                $(this).addClass(activeClass);

                $(this).find('span').text('Loading');

                $.ajax({
                  url: $(this).attr('href'),
                  dataType: 'html',
                  complete: function () {
                    $(this).removeClass(activeClass);
                  },
                  success: function (data) {
                    // update list
                    $ajax_list
                      .append($(data).find('div.posts').html());

                      console.log($ajax_list);

                    // lazyBackground.update();
                    // $ajax_list.refreshSamples();
                    $('.link-card-layout:not(:eq(0))').find('.large-card').removeClass('large-card');

                    // update button
                    $('#ajax-pagination').replaceWith($(data).find('#ajax-pagination'));
                  },
                  error: function () {},
                });
              });
            }




        // Refresh values on resize or rotation
            // var _refresh = function () {
            // };
            // $(window).on('resize', _refresh);
            // $(document).on('ready', _refresh);
            // $(window).on('load', _refresh);




      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        $(window).on('load', function(){

          var text = $('.hero-content h2').text();

          $.fn.highlghtWrap = function () {
            this.each( function () {
              var current = $( this );
              var text = current.text();
              var words = text.split( ' ' );
              var line = '';
              var lines = [];

              current.text( words[ 0 ] );
              var height = current.height();
              line = words[ 0 ];
              for ( var i = 1; i < words.length; i++ ) {
                current.text( current.text() + ' ' + words[ i ] );

                if ( current.height() > height ) {
                  lines.push( line );
                  line = words[ i ];
                  height = current.height();
                } else {
                  line = line + ' ' + words[ i ];
                }
              }
              lines.push( line );
              current.html( '' );
              $.each( lines, function ( v, a ) {
                current.html( current.html() + '<span class="reveal revealer'+v+'">' + a +
                  ' </span>' );
              } );
            } );
          };

          $('.hero-content h2').highlghtWrap();
        });

        // Refresh values on resize or rotation
        var _refresh = function () {

          $('.hero-content h2').highlghtWrap();

        };
        $(window).on('resize', _refresh);
        $(document).on('ready', _refresh);
        $(window).on('load', _refresh);

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'projects': {
      init: function() {


        // SUPER COMPLEX WAY OF GETTING
        // CUSTOM SELECT MENU
        var x, i, j, l, ll, selElmnt, a, b, c;
        /* Look for any elements with the class "select": */
        x = document.getElementsByClassName("select");
        l = x.length;
        function closeAllSelect(elmnt) {
          /* A function that will close all select boxes in the document,
          except the current select box: */
          var x, y, i, xl, yl, arrNo = [];
          x = document.getElementsByClassName("select-items");
          y = document.getElementsByClassName("select-selected");
          xl = x.length;
          yl = y.length;
          for (i = 0; i < yl; i++) {
            if (elmnt === y[i]) {
              arrNo.push(i);
            } else {
              y[i].classList.remove("select-arrow-active");
            }
          }
          for (i = 0; i < xl; i++) {
            if (arrNo.indexOf(i)) {
              x[i].classList.add("select-hide");
            }
          }
        }
        for (i = 0; i < l; i++) {
          selElmnt = x[i].getElementsByTagName("select")[0];
          ll = selElmnt.length;
          /* For each element, create a new DIV that will act as the selected item: */
          a = document.createElement("DIV");
          a.setAttribute("class", "select-selected");
          a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
          x[i].appendChild(a);
          /* For each element, create a new DIV that will contain the option list: */
          b = document.createElement("DIV");
          b.setAttribute("class", "select-items select-hide");
          for (j = 0; j < ll; j++) {
            /* For each option in the original select element,
            create a new DIV that will act as an option item: */
            c = document.createElement("DIV");
            // c.innerHTML = selElmnt.options[j].innerHTML;
            if(j === 0) {
              c.setAttribute('data-value', 'All');
              c.innerHTML = selElmnt.options[j].textContent="All";
            } else if(j > 0) {
              c.setAttribute('data-value', selElmnt.options[j].innerHTML);
              c.innerHTML = selElmnt.options[j].innerHTML;
            }
            c.addEventListener("click", function(e) {
                /* When an item is clicked, update the original select box,
                and the selected item: */
                var y, i, k, s, h, sl, yl;
                s = this.parentNode.parentNode.getElementsByTagName("select")[0];
                sl = s.length;
                h = this.parentNode.previousSibling;
                for (i = 0; i < sl; i++) {
                  if (s.options[i].innerHTML === this.innerHTML) {
                    s.selectedIndex = i;
                    h.innerHTML = this.innerHTML;
                    y = this.parentNode.getElementsByClassName("same-as-selected");
                    yl = y.length;
                    for (k = 0; k < yl; k++) {
                      y[k].removeAttribute("class");
                    }
                    this.setAttribute("class", "same-as-selected");
                    document.getElementById('projectsForm').submit();
                    break;
                  }
                }
                h.click();

            });
            b.appendChild(c);
          }
          x[i].appendChild(b);
          a.addEventListener("click", function(e) {
            /* When the select box is clicked, close any other select boxes,
            and open/close the current select box: */
            e.stopPropagation();
            closeAllSelect(this);
            this.nextSibling.classList.toggle("select-hide");
            this.classList.toggle("select-arrow-active");
          });
        }

        /* If the user clicks anywhere outside the select box,
        then close all select boxes: */
        document.addEventListener("click", closeAllSelect);


        // function link_card_large_image() {
        //   $(window).on('load', function(){

        //     var winW = $(window).outerWidth();

        //     var heights = 0;
        //     var img = '';
        //     var text = '';
        //     $('.link-card:nth-child(2), .link-card:nth-child(3)').each(function(){
        //       heights += parseFloat($(this).height());
        //       img = $(this).find('.image').outerHeight();
        //       text = $(this).find('.title').outerHeight();
        //     });
        //     // console.log(text);
        //     var heightVal = ((img + text) * 2) - 85;
        //     console.log(heightVal);
        //     if(winW > 552) {
        //       $('.large-card .image').css({ height : heightVal });
        //     } else {
        //       $('.large-card .image').css({ height : '' });
        //     }
        //   });
        // }
        // link_card_large_image();

        // var _refresh = function () {
        //   link_card_large_image();
        // };
        // $(window).on('resize', _refresh);
        // $(document).on('ready', _refresh);
        // $(window).on('load', _refresh);



      },
      finalize: function() {

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
